body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #eeeeee;
}

/* --- NAVBAR STYLING --- */

/*Removes the gap between the navbar and bottom of the header*/
.navbar-nav {
  margin-bottom: 0;
}

/* Sets the background and text strength for when a navbar item is highlighted */
.navbar-nav a:hover {
  background-color: #e7e7e7;
  color: rgb(50, 50, 50);
}

/* Sets the highlight colour for the currently selected navbar item */
.navbar-nav .active {
  background-color: #eee;
}

/* These two set the text darkness for unhighlighted navbar options */
.navbar-light .navbar-nav .nav-link {
  color: rgb(100, 100, 100);
}
.dropdown-item {
  color: rgb(100, 100, 100);
}
.dropdown-item.active {
  color: black;
}

/*Styles for larger screens.*/
@media (min-width: 921px) {
  .navbar-nav a {
    background: #fff1ad; /*Sets the link background*/
    min-width: 89px; /*Stops the tabs shrinking to just fit the size of the text, but allows them to expand if they need more room, without them dropping onto a lower line.*/
    padding: 5px; /*Overrides a bootstrap value, which is marge larger and fucks shit up.*/
    border-radius: 10px 10px 0px 0px; /*Curves the button*/
    margin-left: 3px; /*Adds a gap between the buttons.*/
  }

  .navbar-nav .dropdown-menu a {
    width: 96%;
    border-radius: 0px 0px 0px 0px; /* Makes sure the hover highlight isn't curved for drop down menus */
  }
}

/*Styles for phone screens*/
@media (max-width: 920px) {
  .navbar {
    background: #fff1ad; /*Makes area height yellow*/
    text-align: center; /*This centers the menu text options.*/
  }
}

/* Sets the drop down colour to our classic yellow, on both large and small screens */
.dropdown-menu {
  background: #fff1ad; /*Sets the link background*/
}

/* --- END NAVBAR STYLING --- */

.box-shadow--8dp {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.blogContent img {
  width: 75%;
  margin-left: 12.5%;
}
